import { z } from 'zod';

// eslint-disable-next-line import/prefer-default-export
export const patientAppointmentSchema = z.object({
  queue: z.string().nullable().refine((value) => value !== null && value !== '', 'Необходимо выбрать очередь'),
  checkup: z.object({
    patientId: z.number().nullable().refine((value) => value !== null && !Number.isNaN(value), {
      message: 'Выберите существующего пользователя или создайте нового',
    }),
    lastName: z.string().min(1, 'Поле не может быть пустым'),
    firstName: z.string().min(1, 'Поле не может быть пустым'),
    birthDate: z.date()
      .or(z.literal(null))
      .refine((date) => date !== null, "Поле должно быть формата 'дд:мм:гггг' и не пустым"),
    sex: z.boolean().nullable().refine((value) => value !== null, {
      message: 'Поле не может быть пустым',
    }),
    phoneNumber: z.string().regex(/^\+\d \(\d{3}\) \d{3} \d{2}-\d{2}$/, 'Некорректное значение поля'),
    isPhoneConfirmed: z.boolean().refine((val) => val === true, {
      message: 'Телефон не подтвержден',
    }),

  }),
});
